///
///	Spectral by HTML5 UP
///	html5up.net
///	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

// Misc.
	$misc: (
		max-spotlights:		10,
		max-features:		10,
		z-index-base:		10000
	);

// Duration.
	$duration: (
		transitions:		0.2s,
		menu:				0.5s,
		fadein:				0.7s
	);

// Size.
	$size: (
		element-height:		2.75em,
		element-margin:		2em,
		letter-spacing:		0.075em,
		letter-spacing-alt:	0.225em
	);

// Font.
	$font: (
		prose-weight: 400,
		prose-weight-bold: 700,
		weight:				400,
		weight-bold:		600,
		weight-extrabold:	800,
		family-fixed:		('Consolas', 'Inconsolata', 'Monaco', monospace),
		fixed-size:            1em,
		inline-fixed-size:     1.05em,
		fixed-lheight:          1.75em,
		ctrl-family:				('Open Sans', Helvetica, sans-serif),
		ctrl-size-xxlarge:      15pt,
		ctrl-size-xlarge:      13pt,
		ctrl-size-large:      12pt,
		ctrl-size-small:      11pt,
		ctrl-size-xsmall:      9pt,
		ctrl-lheight-normal: 1.65em,
		ctrl-lheight-small: 1.35em,
		ctrl-lheight-smaller: 1.25em,
		ctrl-lheight-smallest: 0.9em,
		ctrl-form-size:       1em,
		ctrl-form-label-size: 0.9em,
		ctrl-bttn-size:       0.8em,
		ctrl-bttn-bigsize:       1.35em,
		ctrl-bttn-lheight:     3.125em,
		prose-family:				('Lora', 'Garamond', serif),
		prose-size-xxlarge:      15pt,
		prose-size-xlarge:      13pt,
		prose-size-large:      12pt,
		prose-size-small:      10pt,
		prose-size-xsmall:      8pt,
		prose-lheight-normal: 1.65em,
		prose-lheight-small: 1.35em,
		prose-lheight-smaller: 1.25em,
		prose-lheight-smallest: 0.9em,
		hdr-size-xxlarge:   1.75em,
		hdr-size-xlarge:    1.35em,
		hdr-size-mlarge:    1.25em,
		hdr-size-large:     1.1em,
		hdr-size-normal:    1.15em,
		hdr-size-small:     1em,
		hdr-size-smaller:   0.9em,
    hdr-size-xsmall:    0.8em,
    hdr-size-xxsmall:   0.7em,
		hdr-lheight-large:   1.75em,
		hdr-lheight-normal:  1.65em,
		hdr-lheight-small:   1.5em,
		hdr-lheight-smaller:   1em
	);

// Palette.
	$palette: (
		bg:					#2e3842,
		fg:					#fff,
		fg-bold:			#fff,
		fg-light:			rgba(255,255,255,0.5),
		border:				#fff,
		border-bg:			rgba(144,144,144,0.25),
		border2:			#fff,
		border2-bg:			rgba(144,144,144,0.5),

		accent1: (
			bg:				#21b2a6,
			fg-bold:		#ffffff,
			fg:				mix(#21b2a6, #ffffff, 25%),
			fg-light:		mix(#21b2a6, #ffffff, 40%),
			border:			rgba(0,0,0,0.125),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(0,0,0,0.25),
			border2-bg:		rgba(255,255,255,0.2)
		),

		accent2: (
			bg:				#00ffcc,
			fg-bold:		#ffffff,
			fg:				mix(#00ffcc, #ffffff, 25%),
			fg-light:		mix(#00ffcc, #ffffff, 40%),
			border:			rgba(0,0,0,0.125),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(0,0,0,0.25),
			border2-bg:		rgba(255,255,255,0.2)
		),

		accent3: (
			bg:				#00f0ff,
			fg-bold:		#ffffff,
			fg:				mix(#00f0ff, #ffffff, 25%),
			fg-light:		mix(#00f0ff, #ffffff, 40%),
			border:			rgba(0,0,0,0.125),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(0,0,0,0.25),
			border2-bg:		rgba(255,255,255,0.2)
		),

		accent4: (
			bg:				#76ddff,
			fg-bold:		#ffffff,
			fg:				mix(#76ddff, #ffffff, 25%),
			fg-light:		mix(#76ddff, #ffffff, 40%),
			border:			rgba(0,0,0,0.125),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(0,0,0,0.25),
			border2-bg:		rgba(255,255,255,0.2)
		),

		accent5: (
			bg:				#505393,
			fg-bold:		#ffffff,
			fg:				mix(#505393, #ffffff, 25%),
			fg-light:		mix(#505393, #ffffff, 40%),
			border:			rgba(0,0,0,0.125),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(0,0,0,0.25),
			border2-bg:		rgba(255,255,255,0.2)
		),

		accent6: (
			bg:				#ed4933,
			fg-bold:		#ffffff,
			fg:				mix(#ed4933, #ffffff, 25%),
			fg-light:		mix(#ed4933, #ffffff, 40%),
			border:			rgba(0,0,0,0.125),
			border-bg:		rgba(255,255,255,0.075),
			border2:		rgba(0,0,0,0.25),
			border2-bg:		rgba(255,255,255,0.2)
		),

		accent7: (
			bg:				#fafafa,
			fg-bold:		#2E3842,
			fg:				#4E4852,
			fg-light:		#8E8892,
			border:			#dfdfdf,
			border-bg:		rgba(0,0,0,0.0375),
			border2:		#bfbfbf,
			border2-bg:		rgba(0,0,0,0.1)
		)
	);